import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import './LazyImage.scss';
import LazySource from './LazySource.jsx';

const LazyImage = ({
  figureClass,
  imageClass,
  children,
  defaultUrl,
  placeholderUrl,
  alt,
  rotation
}) => {
  const imageElmRef = useRef();
  const { ref, inView } = useInView({
    rootMargin: '300px',
    threshold: 1.0,
    triggerOnce: true
  });
  const [imageLoaded, setImageLoaded] = useState(false);
  const defaultPlaceholder = placeholderUrl
    ? placeholderUrl
    : 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mPs+Q8AAh0BjeU/5JAAAAAASUVORK5CYII=';

  useEffect(() => {
    imageElmRef.current.addEventListener('load', setImageLoaded(true));
    if (imageLoaded) {
      imageElmRef.current.removeEventListener('load', setImageLoaded);
    }
  });

  const sourceElements = (items, isVisible) => {
    if (!items) {
      return null;
    } else {
      return Array.isArray(items)
        ? items.map((child, index) => React.cloneElement(child, { key: index, visible: isVisible }))
        : React.cloneElement(items, { visible: isVisible });
    }
  };

  return (
    <figure
      ref={ref}
      className={[
        'lazy-image',
        inView && imageLoaded ? '' : 'lazy-image--pending',
        !inView && !imageLoaded && !placeholderUrl ? 'lazy-image--loading' : '',
        figureClass
      ]
        .filter(Boolean)
        .join(' ')}
    >
      <picture>
        {sourceElements(children, inView)}
        <img
          className={['lazy-image__img', imageClass].filter(Boolean).join(' ')}
          src={inView && imageLoaded ? defaultUrl : defaultPlaceholder}
          alt={alt}
          ref={imageElmRef}
          data-rotation={rotation}
        />
      </picture>
    </figure>
  );
};

LazyImage.propTypes = {
  figureClass: PropTypes.string,
  imageClass: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.objectOf(LazySource), PropTypes.arrayOf(LazySource)]),
  defaultUrl: PropTypes.string,
  placeholderUrl: PropTypes.string,
  alt: PropTypes.string,
  rotation: PropTypes.string
};

export default LazyImage;
