import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectSearch, setSearch } from '../services/ui';
import './SearchBar.scss';

const SearchBar = () => {
  const inputRef = useRef(null);
  const dispatch = useDispatch();
  const searchValue = useSelector(selectSearch);

  const handleSubmit = e => {
    e.preventDefault();
    e.stopPropagation();
    dispatch(setSearch(inputRef.current.value.trim() || undefined));
    inputRef.current.blur();
  };

  const endSearch = () => dispatch(setSearch(undefined));

  // Update input
  useEffect(() => {
    inputRef.current.value = searchValue || '';
  }, [searchValue]);

  return (
    <form action='/' className='SearchBar' onSubmit={handleSubmit}>
      <div className='SearchBar__InputWrapper'>
        <input
          type='text'
          title='Teilnehmer*innen finden'
          placeholder='Teilnehmer*innen finden'
          autoComplete='off'
          autoCorrect='off'
          autoCapitalize='off'
          spellCheck='false'
          ref={inputRef}
          className='SearchBar__Input'
        />
        <button
          className={`SearchBar__Reset ${searchValue ? 'SearchBar__Reset--Visible' : ''}`}
          title='Suche beenden'
          onClick={endSearch}
          type='button'
        >
          <svg className='SearchBar__ResetIcon' viewBox='0 0 320 512'>
            <path d='M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z' />
          </svg>
        </button>
      </div>
      <button type='submit' className='SearchBar__Submit' title='Suchen'>
        Suchen
      </button>
    </form>
  );
};

export default SearchBar;
