import React, { useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import AppRoutes from '../definitions/routes';
import { useGetParticipantsQuery } from '../services/participants';
import './App.scss';
import Detail from './Detail.jsx';
import ErrorBoundary from './ErrorBoundary.jsx';
import Header from './Header.jsx';
import List from './List.jsx';

const App = () => {
  const location = useLocation();
  const animationClassNames = `App__View--Fade-${location.pathname === '/' ? 'Left' : 'Right'}`;

  // Use data fetching status to determine when to hide the loading indicator
  const { isUninitialized, isLoading } = useGetParticipantsQuery();
  useEffect(() => {
    if (!isUninitialized && !isLoading) {
      document.body.classList.add('initialized');
    }
  }, [isUninitialized, isLoading]);

  return (
    <div className='App'>
      <Header />
      <SwitchTransition
        component={null}
        childFactory={child =>
          React.cloneElement(child, {
            classNames: animationClassNames
          })
        }
      >
        <CSSTransition key={location.pathname} classNames={animationClassNames} timeout={150}>
          <Routes location={location}>
            <Route
              path={AppRoutes.List()}
              element={
                <ErrorBoundary>
                  <List />
                </ErrorBoundary>
              }
            />
            <Route
              path={AppRoutes.Detail()}
              element={
                <ErrorBoundary>
                  <Detail />
                </ErrorBoundary>
              }
            />
          </Routes>
        </CSSTransition>
      </SwitchTransition>
    </div>
  );
};

export default App;
