import { createReducer } from '@reduxjs/toolkit';

const REDUCERPATH = 'ui';
const initState = {
  search: undefined,
  lastListScroll: undefined
};

const actionPath = path => `${REDUCERPATH}/${path}`;

export const selectSearch = state => state.ui.search;
export const selectLastListScroll = state => state.ui.lastListScroll;

export const setSearch = keyword => ({
  type: actionPath('search'),
  payload: keyword
});

export const setLastListScroll = offset => ({
  type: actionPath('lastListScroll'),
  payload: offset
});

export const uiReducer = createReducer(initState, builder => {
  builder.addCase(actionPath('search'), (state, action) => {
    state.search = action.payload;
  });
  builder.addCase(actionPath('lastListScroll'), (state, action) => {
    state.lastListScroll = action.payload;
  });
});

uiReducer.reducerPath = REDUCERPATH;
