import PropTypes from 'prop-types';
import React from 'react';

const LazySource = ({ srcSet, mediaMin, customMedia, visible }) => {
  return (
    <source
      media={customMedia ? customMedia : `min-width: ${mediaMin}px`}
      srcSet={visible ? srcSet : null}
    />
  );
};

LazySource.propTypes = {
  srcSet: PropTypes.string,
  mediaMin: PropTypes.number,
  customMedia: PropTypes.string,
  visible: PropTypes.bool
};

export default LazySource;
