import PropTypes from 'prop-types';
import React from 'react';
import './ErrorBoundary.scss';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div className='ErrorBoundary'>
          <svg className='ErrorBoundary__Icon' viewBox='0 0 53.9 53.9'>
            <path
              d='M27,53.9c-14.9,0-27-12.1-27-27C0,12.1,12.1,0,27,0s27,12.1,27,27S41.8,53.9,27,53.9z M27,3.9c-12.7,0-23,10.3-23,23
	c0,12.7,10.3,23,23,23c12.7,0,23-10.3,23-23C50,14.3,39.7,3.9,27,3.9z'
            />
            <path
              d='M15.2,40.1c-0.5,0-1-0.2-1.4-0.5c-0.8-0.8-0.8-2-0.1-2.8c3.6-3.8,8.5-5.8,13.7-5.8c4.6,0,9.1,1.7,12.5,4.7
	c0.8,0.7,0.9,2,0.2,2.8c-0.7,0.8-2,0.9-2.8,0.2c-2.7-2.4-6.3-3.8-9.9-3.8c-4.1,0-8,1.6-10.8,4.6C16.3,39.9,15.7,40.1,15.2,40.1z'
            />
            <circle cx='20.9' cy='24.4' r='2.6' />
            <circle cx='33.1' cy='24.4' r='2.6' />
          </svg>
          <h1 className='ErrorBoundary__Title'>Hoppla, sorry!</h1>
          <p className='ErrorBoundary__Message'>
            Wir haben leider gerade Probleme, <br />
            dir diesen Inhalt anzuzeigen.
          </p>
          <button
            className='ErrorBoundary__Reload'
            title='Seite neu laden'
            onClick={() => window.location.reload()}
          >
            Seite neu laden
          </button>
        </div>
      );
    }

    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.any
};

export default ErrorBoundary;
