import React from 'react';
import { Link, Route, Routes } from 'react-router-dom';
import AppRoutes from '../definitions/routes';
import './Header.scss';
import Logo from '../images/logo.svg';

const Header = () => {
  return (
    <div className='Header'>
      <Link to={AppRoutes.List()} title='Barcamp Koblenz' className='Header__LogoLink'>
        <img
          className='Header__LogoImage'
          src={Logo}
          alt='Barcamp Koblenz'
          width={135}
          height={49}
        />
      </Link>
      <Routes>
        <Route
          path={AppRoutes.List()}
          element={
            <a
              href='https://join.barcamp-koblenz.de'
              target='_blank'
              rel='noopener noreferrer'
              title='Stell dich vor'
              className='Header__Button'
            >
              Stell dich vor
            </a>
          }
        />
        <Route
          path={AppRoutes.Detail()}
          element={
            <Link to={AppRoutes.List()} title='Zurück' className='Header__Button'>
              Zurück
            </Link>
          }
        />
      </Routes>
    </div>
  );
};

export default Header;
