import Fuse from 'fuse.js';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useGetParticipantsQuery } from '../services/participants';
import { selectLastListScroll, selectSearch, setLastListScroll } from '../services/ui';
import './List.scss';
import ParticipantBubble from './ParticipantBubble.jsx';
import SearchBar from './SearchBar.jsx';

const SearchOptions = {
  keys: ['name'],
  threshold: 0.3
};

const List = () => {
  const dispatch = useDispatch();
  const { data, isError } = useGetParticipantsQuery();
  const [filteredData, setFilteredData] = useState(undefined);
  const search = useSelector(selectSearch);
  const lastListScroll = useSelector(selectLastListScroll);
  const [firstRender, setFirstRender] = useState(true);

  // Scroll to last position, if set
  useEffect(() => {
    if (lastListScroll && firstRender) {
      setTimeout(() => window.scrollTo({ top: lastListScroll, behavior: 'smooth' }), 300);
      // Reset the state
      dispatch(setLastListScroll(undefined));
    }
  }, [lastListScroll]);

  useEffect(() => setFirstRender(false));

  useEffect(() => {
    if (search) {
      const fuse = new Fuse(data, SearchOptions);
      setFilteredData(fuse.search(search).map(result => result.item));
    } else {
      setFilteredData(undefined);
    }
  }, [search]);

  if (isError) {
    throw new Error();
  }

  const dataToRender = filteredData || data || [];

  return (
    <div className='List'>
      <h1 className='List__Title'>Die Barcamper 2023:</h1>
      <div className='List__Search'>
        <SearchBar />
      </div>
      {search && dataToRender.length < 1 && (
        <div className='List__NoResults'>
          Für deine Suche nach <b>{search}</b> konnten wir keine Teilnehmer*innen finden.
        </div>
      )}
      <div className='List__Bubbles'>
        {dataToRender &&
          dataToRender.map(participant => (
            <ParticipantBubble participant={participant} key={participant.handle} />
          ))}
      </div>
    </div>
  );
};

export default List;
