import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import AppRoutes from '../definitions/routes';
import Avatar from '../images/avatar.svg';
import { setLastListScroll } from '../services/ui';
import LazyImage from './LazyImage.jsx';
import LazySource from './LazySource.jsx';
import './ParticipantBubble.scss';

const ParticipantBubble = ({ participant }) => {
  const dispatch = useDispatch();
  const memorizeScrollPosition = useCallback(() => {
    dispatch(setLastListScroll(window.scrollY));
  });

  return (
    <Link
      to={AppRoutes.Detail(participant.handle)}
      title={`${participant.name}`}
      className='ParticipantBubble'
      onClick={memorizeScrollPosition}
    >
      {participant.image_webapp ? (
        <LazyImage
          figureClass='ParticipantBubble__ImageWrapper'
          imageClass='ParticipantBubble__Image'
          rotation={participant.image_rotation}
          defaultUrl={participant.image_webapp}
          alt={participant.name}
        >
          <LazySource
            customMedia='screen'
            srcSet={`${participant.image_webapp} 1x, ${participant.image_webapp_retina} 2x`}
          />
        </LazyImage>
      ) : (
        <LazyImage
          figureClass='ParticipantBubble__ImageWrapper'
          imageClass='ParticipantBubble__Image'
          defaultUrl={Avatar}
          alt={participant.name}
        />
      )}
      <div className='ParticipantBubble__Name'>{participant.name}</div>
    </Link>
  );
};

ParticipantBubble.propTypes = {
  participant: PropTypes.object
};

export default ParticipantBubble;
