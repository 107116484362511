import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const participantsApi = createApi({
  reducerPath: 'participantsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL
  }),
  endpoints: builder => ({
    getParticipants: builder.query({
      query: () => '/?shuffle=1'
    })
  })
});

export const { useGetParticipantsQuery } = participantsApi;
