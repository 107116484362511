import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/dist/query';
import { participantsApi } from './participants';
import { uiReducer } from './ui';

export const store = configureStore({
  reducer: {
    [participantsApi.reducerPath]: participantsApi.reducer,
    [uiReducer.reducerPath]: uiReducer
  },
  middleware: getDefaultMiddleware => getDefaultMiddleware().concat(participantsApi.middleware)
});

setupListeners(store.dispatch);
