import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import AppRoutes from '../definitions/routes';
import { Instagram, LinkedIn, Twitter } from '../definitions/socialicons';
import Avatar from '../images/avatar.svg';
import { useGetParticipantsQuery } from '../services/participants';
import './Detail.scss';
import LazyImage from './LazyImage.jsx';
import LazySource from './LazySource.jsx';

const Detail = () => {
  const navigate = useNavigate();
  const { handle } = useParams();
  const { participant, isDone } = useGetParticipantsQuery(undefined, {
    selectFromResult: ({ data, isUninitialized, isLoading }) => ({
      participant: data?.find(p => p.handle === handle),
      isDone: !isUninitialized && !isLoading
    })
  });

  // Scroll to top
  useEffect(() => window.scrollTo({ top: 0, behavior: 'smooth' }, []));

  // Rediret to index if participant is not found
  useEffect(() => {
    if (isDone && !participant) {
      navigate(AppRoutes.List());
    }
  }, [isDone, participant]);

  return !participant ? null : (
    <div className='Detail'>
      <div className='Detail__Content'>
        {participant.image_webapp ? (
          <LazyImage
            figureClass='Detail__Image'
            imageClass='Detail__Image'
            defaultUrl={participant.image_webapp}
            alt={participant.name}
            rotation={participant.image_rotation}
          >
            <LazySource
              customMedia='screen'
              srcSet={`${participant.image_webapp} 1x, ${participant.image_webapp_retina} 2x`}
            />
          </LazyImage>
        ) : (
          <LazyImage
            figureClass='Detail__Image'
            imageClass='Detail__Image'
            defaultUrl={Avatar}
            alt={participant.name}
          />
        )}
        <h1 className='Detail__Name'>{participant.name}</h1>
        <div className='Detail__Company'>{participant.company}</div>
        <div className='Detail__Hashtags'>
          {participant.hashtags.map(tag => `#${tag}`).join(' ')}
        </div>
        <hr className='Detail__Divider' />
        <p className='Detail__QuoteTitle'>Darauf freut {participant.name} sich am meisten:</p>
        <p className='Detail__Quote'>{participant.quote}</p>
        <hr className='Detail__Divider' />
        <p className='Detail__SocialTitle'>
          Hier kannst du dich mit {participant.name} verknüpfen:
        </p>
        <div className='Detail__Social'>
          {participant.linkedin && (
            <a
              href={`https://linkedin.com/in/${participant.linkedin}`}
              title={`Mit ${participant.name} auf LinkedIn verknüpfen`}
              className='Detail__SocialLink'
              target='_blank'
              rel='noopener noreferrer'
            >
              <LinkedIn className='Detail__SocialIcon' size={26} />
            </a>
          )}
          {participant.instagram && (
            <a
              href={`https://instagram.com/${participant.instagram}`}
              title={`${participant.name} auf Instagram folgen`}
              className='Detail__SocialLink'
              target='_blank'
              rel='noopener noreferrer'
            >
              <Instagram className='Detail__SocialIcon' size={26} />
            </a>
          )}
          {participant.twitter && (
            <a
              href={`https://twitter.com/${participant.twitter}`}
              title={`${participant.name} auf Twitter folgen`}
              className='Detail__SocialLink'
              target='_blank'
              rel='noopener noreferrer'
            >
              <Twitter className='Detail__SocialIcon' size={26} />
            </a>
          )}
        </div>
      </div>
    </div>
  );
};

export default Detail;
